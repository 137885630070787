export const ACCOUNT_TYPES = {
  BANK: 'Bank',
  LEDGER: 'Ledger',
  CUSTOMER: 'Customer',
  VENDOR: 'Vendor'
}
export const ACCOUNT_SUB_TYPES = {
  LC_BANK: 'LC_BANK',
  INVESTOR: 'INVESTOR',
  SUPPLIER: 'SUPPLIER',
  VFS: 'VFS',
};
export const ACCOUNT_TYPES_LIST_FOR_DROPDOWN = {
  BANK: ACCOUNT_TYPES.BANK,
  LEDGER: ACCOUNT_TYPES.LEDGER,
  CUSTOMER: ACCOUNT_TYPES.CUSTOMER,
  VFS: ACCOUNT_SUB_TYPES.VFS,
  SUPPLIER: ACCOUNT_SUB_TYPES.SUPPLIER,
  INVESTOR: ACCOUNT_SUB_TYPES.INVESTOR,
  LC_BANK: ACCOUNT_SUB_TYPES.LC_BANK
}

export const GJV_TYPES = {
  DEFAULT: 'DEFAULT',
  PRIMARY: 'PRIMARY',
  GENERAL: 'GENERAL'
}

export const ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER = {
  LC_BANK: 'TP-Cr-LC',
  INVESTOR: 'TP-NCDI',
  VFS: 'TP-Cr-VFS',
};

export const JOURNAL_VOUCHER_CONFIG = [
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.BANK,
    label: ACCOUNT_TYPES.BANK,
    postingProfile: {},
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.LEDGER,
    label: ACCOUNT_TYPES.LEDGER,
    postingProfile: {},
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.CUSTOMER,
    label: ACCOUNT_TYPES.CUSTOMER,
    postingProfile: {
      default: ""
    }
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.INVESTOR,
    label: 'Vendor - Investor',
    postingProfile: {
      source: "api",
      default: ""
    },
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.LC_BANK,
    label: 'Vendor - LC Bank',
    postingProfile: {},
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.VFS,
    label: 'Vendor - VFS',
    postingProfile: {
      source: "api",
      default: ""
    },
  },
  {
    accountType: ACCOUNT_TYPES_LIST_FOR_DROPDOWN.SUPPLIER,
    label: 'Vendor - Supplier',
    postingProfile: {
      source: "api",
      default: ""
    }
  }
]

export const DEFAULT_LEDGER_VALUE_FOR_REGULAR_FLOW = {
  CREDIT : '318017',
  DEBIT : '513036',
  REVERSAL_CREDIT:'318030',
  REVERSAL_DEBIT:'513045'
};

export const DEFAULT_LEDGER_VALUE_FOR_BANK_TO_BANK_FLOW = {
  DEFAULT_CREDIT : '318017',
  DEFAULT_DEBIT:'513036',
  PRIMARY_CREDIT: '322020',
  PRIMARY_DEBIT: '322020',
};

export const POSTING_PROFILE_APPLICABLE_FOR = [ACCOUNT_TYPES_LIST_FOR_DROPDOWN.CUSTOMER, ACCOUNT_TYPES_LIST_FOR_DROPDOWN.INVESTOR, ACCOUNT_TYPES_LIST_FOR_DROPDOWN.LC_BANK, ACCOUNT_TYPES_LIST_FOR_DROPDOWN.SUPPLIER, ACCOUNT_TYPES_LIST_FOR_DROPDOWN.VFS]

export const CONTROLS_TO_RESET = ['accountName', 'accountOwnerId', 'accountNameData', 'postingProfileGroup', 'postingProfile', 'debitAmount', 'creditAmount', 'payeeDebitAmount', 'payeeCreditAmount', 'description' ];

export const TRANSACTION_TYPE = {
  CREDIT : 'CREDIT',
  DEBIT: 'DEBIT'
}

export function transformPayloadForCreate(data, type){
    return {
      transactionNo: type === TRANSACTION_TYPE.CREDIT ? data.transactionNo : data.transactionNumber, //data.utr should be passed since we are displaying utr in transacton no and update the utr if the input is filled
      date: type === TRANSACTION_TYPE.CREDIT ? data.dateReceived : data.paidOnDate,
      amount: type === TRANSACTION_TYPE.CREDIT ? data.amountReceived : data.amountPaid,
      ...data
    }
}

export const DEFAULT_LEDGER_VALUE_FOR_REVERSAL_FLOW = {
  COLLECTION_PARKING_ACCOUNT : '318017',
  COLLECTION_CONTROL_ACCOUNT: '318030',
};
