import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { initializeUnitConversion } from '../utils/unitConversion'
import { StateCodes } from 'src/app/services/StateCodes';
import { transformUOMData } from '../utils/index'
import { map } from 'rxjs/operators';
import { INVOICE_TYPE_ENUM } from '../constants/finance-invoice';
import { EWAY_BILL_STATUS } from '../constants/e-way-bill';

const API_URL = environment.apiEndPoint;
const AUTH_URL = environment.authApiEndPoint;
const CMS_URL = environment.cmsApiEndPoint;
const COMMON_MASTERS_URL = environment.commonMastersAPIEndPoint;
const COMMON_MASTERS_URL_V2 = environment.commonMastersAPIEndPointV2;
const USER_URL = environment.userEndPoint;
const CP_URL = environment.cpHostUrl;

@Injectable({
  providedIn: 'root'
})
export class OmsService {
  public supplierData = new Subject<any>();
  public inputForDropdown = new Subject<any>();
  selectedCompany: string;
  isInternationalCompany: boolean = false;
  isChannelPartner: boolean = false;

  supplierDataObservable = this.supplierData.asObservable();
  constructor(
    private http: HttpClient,
    private _ps: PermissionsService
  ) { }

  private _currentUser: any;
  private _authToken: any;

  public getCurrentUser() {
    return this._currentUser;
  }

  public getAuthToken() {
    this._authToken = localStorage.getItem('authToken');
    return this._authToken;
  }


  public isAuthenticated(): boolean {
    if (this.getCurrentUser()) {
      return true;
    } else {
      return false;
    }
  }

  public setToken(token) {
    // localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('authToken', token);
    // this._currentUser = user;
    this._authToken = token;
  }

  public setUserName(name) {
    localStorage.setItem('userName', name);
  }

  public getUserName() {
    return localStorage.getItem('userName');
  }

  public setUserEmail(name) {
    localStorage.setItem('userEmail', name);
  }

  public getUserEmail() {
    return localStorage.getItem('userEmail');
  }

  public setUserImage(image) {
    localStorage.setItem('userImage', image);
  }

  public getUserImage() {
    return localStorage.getItem('userImage');
  }

  public setUserRole(role) {
    localStorage.setItem('userRole', role);
  }

  public getUserRole() {
    return localStorage.getItem('userRole');
  }

  public setUserId(userId) {
    localStorage.setItem('userId', userId);
  }

  public getUserId() {
    return localStorage.getItem('userId');
  }

  public setUserCompany(company) {
    localStorage.setItem('company', JSON.stringify(company));
  }

  public getUserCompany() {
    return JSON.parse(localStorage.getItem('company'));
  }

  public setSelectedCompany(company) {
    this.selectedCompany = company;
    this.setIsInternationalCompany(company);
  }
  public setSelectedCompanySlug(company) {
    localStorage.setItem('slug', JSON.stringify(company));
  }
  public getSelectedCompanySlug() {
    return JSON.parse(localStorage.getItem('slug'));
  }

  public getSelectedCompanyIdAndSlug() {
    const slug = this.getSelectedCompany() || '';
    const companies = this.getUserCompany() || [];
    return companies.find(ele => ele.slug === slug);
  }

  public getSelectedCompany() {
    return this.selectedCompany;
  }

  public setIsInternationalCompany(company) {
    this.isInternationalCompany = ['zetwerk-usa', 'zetwerk-singapore'].includes(company) ? true : false;
  }

  public getIsInternationalCompany() {
    return this.isInternationalCompany;
  }

  /**
 * Determines if an invoice is applicable for e-Way Bill creation.
 *
 * @param {Object} invoice - The invoice object to evaluate.
 * @param {string} invoice.invoiceType - The type of the invoice (should be of type TAX_INVOICE).
 * @param {Array} [invoice.eWayBills] - An optional array of e-Way Bills associated with the invoice.
 * @param {string} invoice.irnStatus - The status of the IRN associated with the invoice.
 *
 * @returns {boolean} - Returns true if the invoice is applicable for e-Way Bill creation, otherwise false.
 *
 * This method checks if the given invoice meets the criteria for creating an e-Way Bill.
 * It considers the following conditions:
 * - The invoice type must be a TAX_INVOICE.
 * - The invoice must not already have associated e-Way Bills.
 * - The company slug must not be in the list of companies where e-Invoicing is not applicable.
 * - If the invoice's IRN status is set to GENERATED, it is also considered applicable.
 *
 * If all conditions are met, the method returns true; otherwise, it returns false.
 */
  public isApplicableForEWayBillCreation(invoice) {
    const slug = this.getSelectedCompanySlug();
    const eInvoicingNotApplicableCompanies = ['zetfab', 'sharptanks', 'zetwerk-usa', 'pinaka', 'zetchem'];

    if (invoice?.invoiceType === INVOICE_TYPE_ENUM.TAX_INVOICE && !invoice?.eWayBills) {
      if (eInvoicingNotApplicableCompanies.includes(slug) || invoice?.irnStatus === EWAY_BILL_STATUS.GENERATED) {
        return true;
      }
    }
    return false;
  }

  // relying on hostname as we have a requirement of showing different login pages based on host
  public setIsChannelPartner() {
    this.isChannelPartner = window.location.hostname === CP_URL;
  }

  public getIsChannelPartner() {
    return this.isChannelPartner;
  }

  /**
 * it is used to display 'Company' instead of 'Zetwerk' in UI for Channel Partners
 * eg, 'Zetwerk Banks' tab should be 'Company Banks'
*/
  public getCompanyNameToDisplay() {
    return this.isChannelPartner ? 'Company' : 'Zetwerk';
  }

  public getUserDetails() {
    return {
      name: this.getUserName(),
      email: this.getUserEmail(),
      _id: this.getUserId()
    };
  }

  public setUserPermissions(permissions) {
    this._ps.setPermissions(permissions);
  }

  private deleteUserAndToken() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('authToken');
    this._currentUser = null;
    this._authToken = null;
  }

  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return {
        body: response.body,
        headers: response.headers
      };
    }
    return JSON.parse(response.body);
  }

  public submitLogin(token) {
    const _body = {
      token
    };
    return this.http.post(AUTH_URL + 'authenticate', _body);
  }

  public authenticateZetpro(url, data) {
    return this.http.post(url, data);
  }

  public getCurrencies() {
    return this.http.get(COMMON_MASTERS_URL + '/currency?getAllDocuments=true').pipe(
      map((response: any) => {
        const responseData = {
          data: response?.data?.allDocuments,
          message: response.message,
          status: response.status,
          success: response.success
        }
        return responseData;
      }),
    );;
  }

  public getUnits() {
    return this.http.get(COMMON_MASTERS_URL + 'uom').pipe(
      map((response) => {
        transformUOMData({ data: response['data'] })
        return response;
      }),
    );
    // return this.http.get(API_URL + 'unit');
  }

  public loadUnitConvertor(units) {
    return initializeUnitConversion(units)
  }

  public changeValue(value: any): void {
    this.supplierData.next(value);

  }

  public getCountries() {
    return this.http.get(API_URL + 'countries');
  }

  public getZetwerkDetails() {
    return this.http.get(API_URL + 'zetwerk-details');
  }

  public getZetExportetails(gst) {
    return this.http.get(`${API_URL}zetwerk-export-details/${gst}`);
  }

  public getMiscDetails(type) {
    return this.http.get(API_URL + 'miscellaneous-details', {
      params: {
        detailType: type
      }
    });
  }

  public getBankAccounts({ queryIds, queryParams, params, ...otherParams }: { queryIds?: string[]; params?: any; [key: string]: any } = {}) {
    let query = '';

    if (queryIds && queryIds.length > 0) {
        query = `?ids=${queryIds.join('&ids=')}`;
    }
    if(queryParams){
      query  = '?' + Object.keys(queryParams).map(key => `${key}=${encodeURIComponent(queryParams[key])}`).join('&');
    }
    
    const url = `${API_URL}bank-account${query}`;
    return this.http.get(url, { params: { ...params, ...otherParams } });
}



  public uploadZetwerkBankExcel(file: any): Observable<any> {
    return this.http.post(`${API_URL}bank-account/excel-upload`, file, {
      headers: new HttpHeaders({ timeout: `${600000}` })
    });
  }

  public downloadZetwerkBankExcel(): Observable<any> {
    return this.http.get(`${API_URL}bank-account/excel-download`);
  }

  public getInvestors(params) {
    return this.http.get(API_URL + 'investor', { params });
  }

  public uploadInvestorExcel(file: any): Observable<any> {
    return this.http.post(`${API_URL}investor/excel-upload`, file, {
      headers: new HttpHeaders({ timeout: `${600000}` })
    });
  }

  public downloadInvestorExcel(): Observable<any> {
    return this.http.get(`${API_URL}investor/excel-download`);
  }

  public getByPath(path, urlParams = {}) {
    return this.http.get(API_URL + path, { params: urlParams });
  }

  public updatedByPath(path, data) {
    return this.http.put(API_URL + path, data);
  }

  public getStates() {
    return StateCodes.map((state) => {
      return {
        name: state.name
      };
    });
  }

  public getGSTDetails(gstNumber) {
    return this.http.get(CMS_URL + '/customers/verify-gst/' + gstNumber, {});
  }

  public getZetwerkGST() {
    return this.http.get(API_URL + '/zetwerk-gsts');
  }

  public getReportList() {
    return this.http.get(API_URL + '/tasks');
  }

  public getAllProjectTypes() {
    return this.http.get(API_URL + '/all-project-types');
  }

  public updateProjectType(projectTypeId, data) {
    return this.http.put(API_URL + '/project-type/' + projectTypeId, data);
  }

  public getCurrencyConversionRate(fromCurrencyCode, toCurrencyCode, date = null) {
    let url = '';
    if (date) {
      url = `${COMMON_MASTERS_URL}currency-exchange/?from=${fromCurrencyCode}&to=${toCurrencyCode}&date=${date}`
    } else {
      url = `${COMMON_MASTERS_URL}currency-exchange/?from=${fromCurrencyCode}&to=${toCurrencyCode}`
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        const responseData = {
          data: response?.data?.exchangeRate,
          message: response.message,
          status: response.status,
          success: response.success
        }
        return responseData;
      }),
    );
  }
  public getCurrencyByCode(code) {
    return this.http.get(`${COMMON_MASTERS_URL}currency/code/${code}`);
  }

  public getCalculationSummary(interestNoteId) {
    return this.http.get(`${API_URL}supplier-payment-summary/interest-notes/${interestNoteId}/calculation-summary`);
  }

  public isCurrencyEditable(supplierId, currencyId) {
    return this.http.get(`${API_URL}/suppliers/${supplierId}/validate-currency/${currencyId}`);
  }

  public isCurrencyDetailsAvailabel() {
    let companyDetails = JSON.parse(localStorage.getItem('company'));
    for (const company of companyDetails) {
      if (!company.companyDetails) {
        return false;
      }
      if (!company.companyDetails.baseCurrencyDetails) {
        return false
      }
    }
    return true;
  }

  getBankAccountDetailsUsingIfscCode(ifscCode) {
    return this.http.get(`${API_URL}/validateIfsc`, {
      params: {
        ifscCode
      }
    });
  }

  getUnitsFromCommonMaster() {
    return this.http.get(COMMON_MASTERS_URL + 'uom');
  }


  getItemDataFromItemMasterByPageNumber({ companyId, itemCodes }) {
    const path = COMMON_MASTERS_URL + 'items/get-items-by-codes?pageNumber=1&recordsPerPage=';
    let response = this.http.post(`${path}${itemCodes.length}`,
      { itemCodes: itemCodes, companyId });
    return response;
  }
  inputForDropdownSendFn(data) {
    this.inputForDropdown.next(data)
  }

  fetchAllTdsRates(param) {
    return this.http.get<{ status: string, success: boolean, data: [], message: string }>(API_URL + 'purchase-order/get-all-tds-rate', {
      params: param
    });
  }
  fetchTdsRatesByItems(payload) {
    const path = API_URL + 'purchase-order/get-applicable-tds-rate';
    return this.http.post(`${path}`,
      payload);
  }
  fetchTdsRatesByPurchaseOrderId({ purchaseOrderId, params }:any) {
    const path = API_URL + `purchase-order/get-tds-of-po/${purchaseOrderId}`;
    return this.http.get<{ status: string, success: boolean, data: [], message: string }>(`${path}`, {
      params
    }); 
  }

  fetchAllBUsByConfigType(configType) {
    const path = `${API_URL}business-units/all-bus-by-config-type?configType=${configType}`;
    return this.http.get(path);
  }

  fetchAllBUsForSegmentFromZetAuth(companySlug, segmentId) {
    const path = `${USER_URL}business-unit/getBusinessUnitByQuery?segments._id=${segmentId}&company.slug=${companySlug}`;
    return this.http.get(path);
  }

  fetchAllBUsFromZetAuth(companySlug) {
    const path = `${USER_URL}business-unit/getBusinessUnitByQuery?company.slug=${companySlug}`;
    return this.http.get(path);
  }

  getLocation(params: any) {
    const url = `${COMMON_MASTERS_URL}geo/city`
    return this.http.get(url, { params });
  }

  getCoaFromCommonMasterV2(params) {
    return this.http.get(COMMON_MASTERS_URL_V2 + 'coa', {
      params,
      headers: new HttpHeaders({ 'x-service-version': '1.0.0', 'X-Request-Id': Date.now().toString() })
    });
  }

  getPostingProfileFromCommonMasterV2(params) {
    return this.http.get(COMMON_MASTERS_URL_V2 + 'posting-profile', {
      params,
      headers: new HttpHeaders({ 'x-service-version': '1.0.0', 'X-Request-Id': Date.now().toString() })
    });
  }

  discardNote(noteId, noteType) {
    noteType = noteType === 'creditNote' ? 'supplier-credit-note' : 'supplier-debit-notes';
    const path = `${API_URL}${noteType}/${noteId}/discard`;
    return this.http.put(path, {});
  }
  
  verifyCookie() {
    return this.http.post(AUTH_URL + 'verify-cookie', {});
  }

  logOutWithCookie() {
    return this.http.post(AUTH_URL + 'clear-cookie', {});
  }

  fetchTenantById({tenantId}){
    const path = `${USER_URL}tenant/${tenantId}`;
    return this.http.get(path);
  }

  getValidBankAccounts(supplierGroupId) {
    const path = `${API_URL}supplier-groups-info/${supplierGroupId}/valid-bank-accounts`;
    return this.http.get(path);
  }

}
