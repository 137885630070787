import { CurrencySymbolDecodePipe } from './../../pipes/currency-symbol-decode.pipe';
import { SubtextCellComponent } from './zet-table/components/subtext-cell/subtext-cell.component';
import { CurrencyCellComponent } from './zet-table/components/currency-cell/currency-cell.component';
import { ZetTableModule } from './zet-table/zet-table.module';
import { DateCellComponent } from './zet-table/components/date-cell/date-cell.component';
import { CommonCellComponent } from './zet-table/components/common-cell/common-cell.component';
import { GooglePlacesDirective } from './../../directives/google-location.directive';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageViewerModule } from 'ngx-image-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { FormatUnderscorePipe } from 'src/app/pipes/format-underscore.pipe';
import { PaymentTypePipe } from 'src/app/pipes/payment-type.pipe';
import { TimelineHorizontalComponent } from '../../components/general/timeline-horizontal/timeline-horizontal.component';
import { PaymentChargesComponent } from '../../components/payment-process/payment-charges/payment-charges.component';
import { CreateInvoiceComponent } from '../../components/shared/create-invoice/create-invoice.component';
import { GenericNoteComponent } from '../../components/shared/generic-note/generic-note.component';
import { NumberFormatDirective } from '../../directives/numberFormat.directive';
import { CompanyDisplayNameDirective } from '../../directives/company-display-name.directive';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { Interceptor } from '../../interceptor';
import { ConvertCurrencyNumberToWordPipe } from '../../pipes/convert-currency-number-to-word.pipe';
import { DateTimeFormatPipe } from '../../pipes/date-time-format.pipe';
import { FormatNumberPipe } from '../../pipes/formatNumber.pipe';
import { GenerateName } from '../../pipes/generateName.pipe';
import { BillInvoiceDetailsComponent } from '../bills/invoice/bills-view/bill-invoice-details/bill-invoice-details.component';
import { BillPaymentsComponent } from '../bills/invoice/bills-view/bill-payments/bill-payments.component';
import { TimelineTableComponent } from '../bills/invoice/bills-view/bill-timeline/timeline-table/timeline-table.component';
import { ProformaPaymentScheduleComponent } from '../contract/contract-view/contract-invoice/payment-schedule/proforma-payment-schedule/proforma-payment-schedule.component';
import { TaxPaymentScheduleComponent } from '../contract/contract-view/contract-invoice/payment-schedule/tax-payment-schedule/tax-payment-schedule.component';
import { PreviewInvoiceComponent } from '../contract/contract-view/preview-invoice/preview-invoice.component';
import { PreviewNoteComponent } from '../contract/contract-view/preview-note/preview-note.component';
import { ContractComponent } from '../contract/contract.component';
import { ConfirmComponent } from '../general/confirm/confirm.component';
import { ChangeStatusPopupComponent } from '../contract/change-status-popup/change-status-popup.component'
import { ConfirmationPopupComponent } from '../general/confirmation-popup/confirmation-popup.component';
import { FileDownloaderComponent } from '../general/file-downloader/file-downloader.component';
import { FileUploaderComponent } from '../general/file-uploader/file-uploader.component';
import { FilterGridComponent } from '../general/filter-grid/filter-grid.component';
import { PdfFilePreviewComponent } from '../general/pdf-file-preview/pdf-file-preview.component';
import { TimelineV2Component } from '../general/timeline-v2/timeline-v2.component';
import { TimelineComponent } from '../general/timeline/timeline.component';
import { CreateGstSupplierComponent } from '../gst/create-gst-supplier/create-gst-supplier.component';
import { OrderPaymentsComponent } from '../order/order-payments/order-payments.component';
import { NoteSummaryComponent } from '../order/supplier-note-details/note-summary/note-summary.component';
import { AddCustomerBankAccComponent } from '../payment-process-customer/add-customer-bank-acc/add-customer-bank-acc.component';
import { PaymentProcessCustomerComponent } from '../payment-process-customer/payment-process-customer.component';
import { AddSecondaryPaymentModalComponent } from '../payment-process/add-secondary-payment-modal/add-secondary-payment-modal.component';
import { PaymentMethodsComponent } from '../payment-process/payment-methods/payment-methods.component';
import { PaymentProcessComponent } from '../payment-process/payment-process.component';
import { SecondaryAccountComponent } from '../payment-process/secondary-account/secondary-account.component';
import { CommonFinanceComponent } from '../payment/payment-view/common-finance/common-finance.component';
import { RejectDocumentComponent } from '../payment/payment-view/payment-documents/reject-document/reject-document.component';
import { MarkPaymentReceivedComponent } from '../shared/mark-payment-received/mark-payment-received.component';
import { AdvanceSplitsComponent } from '../suppliers/supplier-ledger/advance-splits/advance-splits.component';
import { AdvancePoSummaryComponent } from './advance-po-summary/advance-po-summary.component';
import { BankDetailsSelectComponent } from './bank-details-select/bank-details-select.component';
import { BillCreatePaymentScheduleComponent } from './bill-create-payment-schedule/bill-create-payment-schedule.component';
import { BillNotePreviewComponent } from './bill-note-preview/bill-note-preview.component';
import { CreateNoteComponent } from './create-note/create-note.component';
import { GeneralSelectDropdownComponent } from './general-select-dropdown/general-select-dropdown.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { ChargesAccordionComponent } from './invoices/charges-accordion/charges-accordion.component';
import { InvoiceSummaryAccordionComponent } from './invoices/invoice-summary-accordion/invoice-summary-accordion.component';
import { NoteListComponent } from './invoices/note-list/note-list.component';
import { PaymentScheduleAccordionComponent } from './invoices/payment-schedule-accordion/payment-schedule-accordion.component';
import { LedgerDetailsComponent } from './ledger-details/ledger-details.component';
import { LedgerListComponent } from './ledger-list/ledger-list.component';
import { MarkTransactionMenuComponent } from './mark-transaction-menu/mark-transaction-menu.component';
import { AddressComponent } from './modal/address/address.component';
import { BillNumberComponent } from './modal/bill-number/bill-number.component';
import { ContractNumberComponent } from './modal/contract-number/contract-number.component';
import { EInvoiceSelectComponent } from './modal/e-invoice-select/e-invoice-select.component';
import { MarkTransactionPaidPopupComponent } from './modal/mark-transaction-paid-popup/mark-transaction-paid-popup.component';
import { PaymentOTPinputComponent } from './modal/payment-otp-input/payment-otp-input.component';
import { StatusNotificationComponent } from './modal/status-notification/status-notification.component';
import { NoteReasonComponent } from './note-reason/note-reason.component';
import { NoteComponent } from './note/note.component';
import { UnallocatedAmountComponent } from './unallocated-amount/unallocated-amount.component';
import { CurrencyFormatterPipe } from 'src/app/pipes/currencyFormatter.pipe';
import { BillFinanceComponent } from '../bills/invoice/bills-view/bill-finance/bill-finance.component';
import { BillDetailsComponent } from '../bills/invoice/bills-view/bill-details/bill-details.component';
import { BillTimelineComponent } from '../bills/invoice/bills-view/bill-timeline/bill-timeline.component';
import { DocumentFileUploadComponent } from '../bills/invoice/bills-view/bill-details/document-file-upload/document-file-upload.component';
import { GeneralRemarkComponent } from '../bills/invoice/bills-view/bill-details/general-remark/general-remark.component';
import { FactoryTaxInvoiceComponent } from '../finance/finance/factory-payment/factory-tax-invoice/factory-tax-invoice.component';
import { FactoryBillInvoiceDetailsComponent } from '../finance/finance/factory-payment/factory-payment-details/factory-bill-invoice-details/factory-bill-invoice-details.component';
import { BillTaxInvoiceComponent } from '../bills/invoice/bills-view/bill-details/documents/bill-tax-invoice/bill-tax-invoice.component';
import { PaymentRequestUploadViewComponent } from '../payment/payment-request/payment-request-upload-view/payment-request-upload-view.component';
import { ProformaInvoiceComponent } from '../payment/payment-request/proforma-invoice/proforma-invoice.component';
import { SelectPaymentRequestComponent } from '../payment/payment-request/select-payment-request/select-payment-request.component';
import { TaxAgainstInvoiceComponent } from '../payment/payment-request/tax-against-invoice/tax-against-invoice.component';
import { PaymentViewComponent } from '../payment/payment-view/payment-view.component';
import { PaymentDetailsComponent } from '../payment/payment-view/payment-details/payment-details.component';
import { PaymentDocumentsComponent } from '../payment/payment-view/payment-documents/payment-documents.component';
import { PerfomaInvoiceAdvanceComponent } from '../payment/payment-view/payment-documents/payment-request/adhoc/perfoma-invoice-advance/perfoma-invoice-advance.component';
import { ProformInvoiceComponent } from '../payment/payment-view/payment-documents/payment-request/adhoc/proform-invoice/proform-invoice.component';
import { InspectionComponent } from '../payment/payment-view/payment-documents/payment-request/advance/inspection/inspection.component';
import { PdcComponent } from '../payment/payment-view/payment-documents/payment-request/advance/pdc/pdc.component';
import { EWayBillComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/e-way-bill/e-way-bill.component';
import { InpectionReportComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/inpection-report/inpection-report.component';
import { LrComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/lr/lr.component';
import { PackingListComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/packing-list/packing-list.component';
import { PaymentWighSlipComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/payment-wigh-slip/payment-wigh-slip.component';
import { TaxInvoiceComponent } from '../payment/payment-view/payment-documents/payment-request/against-tax-invoice/tax-invoice/tax-invoice.component';
import { PaymentFinanceComponent } from '../payment/payment-view/payment-finance/payment-finance.component';
import { PendingPaymentComponent } from '../payment/payment-view/pending-payment/pending-payment.component';
import { PaymentViewRouteComponent } from '../payment/payment-view-route/payment-view-route.component';
import { AdvanceComponent } from '../payment/payment-request/advance/advance.component';
import { PaymentDetailFactoryComponent } from '../payment/payment-view/payment-detail-factory/payment-detail-factory.component';
import { ZetwerkDatatableModule } from './data-table/data-table.module';
import { FilterGridModule } from './filter-grid/filter-grid.module';
import { SupplierNoteViewComponent } from '../order/supplier-note-details/supplier-note-view.component';
import { SupplierNoteDetailsTimeline } from '../order/supplier-note-details/supplier-note-details-timeline/supplier-note-details-timeline.component';
import { SupplierNoteDetailsComponent } from '../order/supplier-note-details/supplier-note-details/supplier-note-details.component';
import { SupplierNoteFinanceComponent } from '../order/supplier-note-details/supplier-note-finance/supplier-note-finance.component';
import { SupplierPaymentsComponent } from '../order/supplier-note-details/supplier-payments/supplier-payments.component';
import { TcsAmountComponent } from './modal/tcs-amount/tcs-amount.component';
import { MoveInvoiceContractComponent } from './invoice-details/move-invoice-contract/move-invoice-contract.component';
import { InvoiceUpdateComponent } from './invoice-details/invoice-update/invoice-update.component';
import {InvoiceApprovalCreationComponent} from './invoice-details/invoice-approval-creation/invoice-approval-creation.component'
import { InvoiceApprovalConfirmationComponent } from './invoice-details/invoice-approval-confirmation/invoice-approval-confirmation.component';
import { AddBoqToInvoiceComponent } from './create-invoice/add-boq-to-invoice/add-boq-to-invoice.component';
import { RaBillDetailsComponent } from '../finance/ra-bill/ra-bill-details/ra-bill-details.component';
import { RaBillItemDetailsComponent } from '../finance/ra-bill/ra-bill-item-details/ra-bill-item-details.component';
import { RaBillDocumentComponent } from '../finance/ra-bill/ra-bill-document/ra-bill-document.component';
import { RabillExcelUploadComponent } from '../finance/ra-bill/rabill-excel-upload/rabill-excel-upload.component';
import { FilterModule, TableModule } from '@zetwerk/zet-filter-table';
import { ZetListModule } from '@zetwerk/zet-list';
import { DocReviewedBlockComponent } from '../payment/payment-view/payment-documents/payment-request/doc-reviewed-block/doc-reviewed-block.component';
import { PoPaymentTermsViewComponent } from '../payment/payment-request-new/po-payment-terms-view/po-payment-terms-view.component'

import { InvoiceMapRabillComponent } from '../finance/finance/invoice-request/invoice-map-rabill/invoice-map-rabill.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StatusCellComponent } from './zet-table/components/status-cell/status-cell.component';
import { InputTrimDirective } from 'src/app/directives/trim-input.directive';
import { TrimStatusDirective } from 'src/app/directives/trim-status.directive'
import { PositiveNumbersDirective } from 'src/app/directives/positive-numbers.directive';
import { NaturalNumbersDirective } from 'src/app/directives/natural-numbers.directive';
import { AddressDetailsComponent } from './create-invoice/address-details/address-details.component';
import { NotifyAddressPopupComponent } from './create-invoice/notify-address-popup/notify-address-popup.component';
import { MarkGrnMrnComponent } from './invoice-details/mark-grn-mrn/mark-grn-mrn.component';
import { MultipleFilesViewerComponent } from './invoice-details/multiple-files-viewer/multiple-files-viewer.component';
import { RejectCellComponent } from '../finance/invoice-discounting/reject-cell/reject-cell.component';
import { InterestCellComponent } from './zet-table/components/interest-cell/interest-cell.component';
import { SupplierLedgerDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-ledger-details.component';
import { SupplierBillDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-bill-details/supplier-bill-details.component';
import { SupplierDebitNoteDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-debit-note-details/supplier-debit-note-details.component';
import { SupplierInterestNoteDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-interest-note-details/supplier-interest-note-details.component';
import { SupplierPaymentDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-payment-details/supplier-payment-details.component';


import { SupplierCreditNoteDetailsComponent } from '../suppliers/supplier-ledger/supplier-ledger-details/supplier-credit-note-details/supplier-credit-note-details.component';
import { FinanceNoteSummaryAccordionComponent } from '../finance/note/finance-note-summary-accordion/finance-note-summary-accordion.component'
import { ItemListBoqDetailComponent } from '../general/item-list-boq-detail/item-list-boq-detail.component';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { SupplierNotePickItemPopupComponent } from './note/supplier-note-pick-item-popup/supplier-note-pick-item-popup.component';
import { SubtextDateCellComponent } from './zet-table/components/subtext-date-cell/subtext-date-cell.component';
import { PrPaymentScheduleComponent } from '../payment/payment-view/pr-payment-schedule/pr-payment-schedule.component';
import { TimelineStepperComponent } from './timeline-stepper/timeline-stepper.component';
import { TimelineStepperItemDirective } from './timeline-stepper/timeline-stepper.directive';
import { ActionsCellComponent } from './zet-table/components/actions-cell/actions-cell.component';
import { DateSubtextCellComponent } from './zet-table/components/date-subtext-cell/date-subtext-cell.component';
import { MapBillToInvoiceComponent } from './invoice-details/map-bill-to-invoice/map-bill-to-invoice.component';
import { DateSubCurrencyCellComponent } from './zet-table/components/date-sub-currency-cell/date-sub-currency-cell.component';
import { TransactionLcDuedateComponent } from './transaction-lc-duedate/transaction-lc-duedate.component';
import { LinkCellComponent } from './zet-table/components/link-cell/link-cell.component';
import { ITimelineConfig, StepperModule, TimelineMode, TimelineModule, TimelineOrientation, TIMELINE_CONFIG, WidgetModule, AddressModule,ItemMasterModule, FinancialDimensionModule, CommonMasterConfig, FileUploadModule, BankMasterModule, ZetAppSharedModule } from '@zetwerk/zetui';
import { EditFinancierTransactionDetailsComponent } from './edit-financier-transaction-details/edit-financier-transaction-details.component';
import { PaymentTicketTabComponent } from './payment-ticket-tab/payment-ticket-tab.component';
import { WarningCardComponent } from '../general/warning-card/warning-card.component';
import { ZetTimelineCustomStepperComponent } from './zet-timeline-custom-stepper/zet-timeline-custom-stepper.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { NoteReasonChangeComponent } from './note-reason-change/note-reason-change.component';
import { SubtextCurrencyCellComponent } from './zet-table/components/subtext-currency-cell/subtext-currency-cell.component';
import { PaymentPlanComponent } from './modal/payment-plan/payment-plan.component';
import { GrnApprovalConfirmationComponent } from './invoice-details/grn-approval-confirmation/grn-approval-confirmation/grn-approval-confirmation.component';
import { ImportBillItemsComponent } from './create-invoice/import-bill-items/import-bill-items.component';
import { FinancialDimensionCardComponent } from '../general/financial-dimension-card/financial-dimension-card.component';
import { CustomPluralPipe } from 'src/app/pipes/plural.pipe';
import { NewBoqListViewComponent } from './note/new-boq-list-view/new-boq-list-view.component';
import { ConfirmationPopupV2Component } from '../general/confirmation-popup-v2/confirmation-popup-v2.component';
import { NarrationCellComponent } from './zet-table/components/narration-cell/narration-cell.component';
import { TransactionNumberCellComponent } from './zet-table/components/transaction-number-cell/transaction-number-cell.component';
import { NewInvoiceItemsComponent } from './create-invoice/new-invoice-items/new-invoice-items/new-invoice-items.component';
import { NewAddBoqToInvoiceComponent } from './create-invoice/new-add-boq-to-invoice/new-add-boq-to-invoice.component';
import { AddressDetailsV2Component } from './create-invoice/address-details-v2/address-details-v2.component';
import { CreateInvoiceOldItemsComponent } from './create-invoice/create-invoice-old-items/create-invoice-old-items.component';
import { DatesEnabledPickerComponent } from './dates-enabled-picker/dates-enabled-picker.component';
import { SiteWarehouseCardComponent } from 'src/app/components/general/site-warehouse-card/site-warehouse-card.component';
import { MarkLCRepaidPopupComponent } from './modal/mark-lc-repaid/mark-lc-repaid.popup.component';
import { NewGenericNoteItemsComponent } from './generic-note/new-generic-note-items/new-generic-note-items.component';
import { NewGenericNotesBoqItemsComponent } from './generic-note/new-generic-notes-boq-items/new-generic-notes-boq-items.component';
import { GenericNoteOldItemsComponent } from './generic-note/generic-note-old-items/generic-note-old-items.component';
import { ActionPermissionDirective } from 'src/app/directives/action-permission.directive';
import { ContractSummaryDetailsComponent } from '../contract/create-contract-v3/contract-summary-details/contract-summary-details.component';
import { ContractComparisonViewComponent } from '../contract/create-contract-v3/contract-comparison-view/contract-comparison-view.component';
import { ParticularComponent } from '../contract/create-contract-v3/particular/particular.component';
import { ReversePaymentPopupComponent } from './reverse-payment-popup/reverse-payment-popup.component';
import { InvoiceSupportingDocsComponent } from './create-invoice/invoice-supporting-docs/invoice-supporting-docs.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { AuditLogDetailsPopupComponent } from './audit-logs/audit-log-details-popup/audit-log-details-popup.component';
import { MarkFailedTransactionSuccessPopupComponent } from './mark-failed-transaction-success-popup/mark-failed-transaction-success-popup.component';
import { WorkflowAuditLogsComponent } from './workflow-audit-logs/workflow-audit-logs.component';
import { DropdownWithSeparatorComponent } from './dropdown-with-separator/dropdown-with-separator.component';
import { IndirectReceiptComponent } from './indirect-receipt/indirect-receipt.component';
import { GeneralJournalVouchersComponent } from './indirect-receipt/general-journal-vouchers/general-journal-vouchers.component';
import { MixpanelButtonTrackDirective } from 'src/app/directives/mixpanel-button-track.directive';
import { IndirectPaymentComponent } from './indirect-payment/indirect-payment.component';
import { BankToBankGeneralJournalVouchersComponent } from './indirect-receipt/bank-to-bank-general-journal-vouchers/bank-to-bank-general-journal-vouchers.component';
import { BankTransactionListPopupComponent } from './indirect-receipt/bank-transaction-list-popup/bank-transaction-list-popup.component';
import { BankTransactionCardComponent } from './indirect-receipt/bank-transaction-card/bank-transaction-card.component';
import { AmountFormatterPipe } from 'src/app/pipes/amountFormatter.pipe';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { DocVerificationSummaryComponent } from './doc-verification-summary/doc-verification-summary.component';
import { DocVerificationSummaryWrapperComponent } from './doc-verification-summary/doc-verification-summary-wrapper/doc-verification-summary-wrapper.component';
import { DocVerificationComponent } from './doc-verification/doc-verification.component'
import { ApprovalPendingViewComponent } from './approval-pending-view/approval-pending-view.component';
import { QrSummaryComponent } from './doc-verification-summary/qr-summary/qr-summary.component';
import { EWayBillInvoiceComponent } from './create-invoice/e-way-bill-invoice/e-way-bill-invoice.component';
import { InvoiceDocumentsComponent } from './create-invoice/invoice-documents/invoice-documents.component';

export const timelineConfig:ITimelineConfig = {
  orientation: TimelineOrientation.VERTICAL,
  mode: TimelineMode.STICK
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    ImageViewerModule.forRoot(),
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true
    }), // ToastrModule added
    NgxPaginationModule,
    Ng2GoogleChartsModule,
    NgxExtendedPdfViewerModule,
    NgSelectModule,
    PdfViewerModule,
    NgMultiSelectDropDownModule,
    UiSwitchModule,
    ZetwerkDatatableModule,
    FilterGridModule,
    TableModule.forRoot({
      frameworkComponents: {
        'commonCellRenderer': CommonCellComponent,
        'dateCellRenderer': DateCellComponent,
        'currencyCellRenderer': CurrencyCellComponent,
        'subtextCellRenderer': SubtextCellComponent,
        'subtextDateCellRenderer': SubtextDateCellComponent,
        'statusCellRenderer': StatusCellComponent,
        'rejectCellComponent': RejectCellComponent,
        'interestCellComponent': InterestCellComponent,
        'actionCellRenderer': ActionsCellComponent,
        'dateSubTextCellRenderer': DateSubtextCellComponent,
        'dateSubCurrencyCellRenderer': DateSubCurrencyCellComponent,
        'linkCellRenderer': LinkCellComponent,
      }
    }),
    FilterModule,
    ZetTableModule,
    ScrollingModule,
    ZetListModule.forRoot({
      frameworkComponents: {
        'commonCellRenderer': CommonCellComponent,
        'dateCellRenderer': DateCellComponent,
        'currencyCellRenderer': CurrencyCellComponent,
        'subtextCellRenderer': SubtextCellComponent,
        'subtextDateCellRenderer': SubtextDateCellComponent,
        'subtextCurrencyCellRenderer': SubtextCurrencyCellComponent,
        'statusCellRenderer': StatusCellComponent,
        'rejectCellComponent': RejectCellComponent,
        'interestCellComponent': InterestCellComponent,
        'actionCellRenderer': ActionsCellComponent,
        'dateSubTextCellRenderer': DateSubtextCellComponent,
        'dateSubCurrencyCellRenderer': DateSubCurrencyCellComponent,
        'linkCellRenderer': LinkCellComponent,
        'narrationCellRenderer': NarrationCellComponent,
        'transactionCellRenderer': TransactionNumberCellComponent,
      }
    }),
    StepperModule,
    TimelineModule,
    WidgetModule,
    AddressModule,
    ItemMasterModule,
    FinancialDimensionModule,
    FileUploadModule,
    BankMasterModule,
    ZetAppSharedModule

  ],
  declarations: [
    NumberFormatDirective,
    FormatNumberPipe,
    AmountFormatterPipe,
    DateTimeFormatPipe,
    FormatUnderscorePipe,
    CustomPluralPipe,
    ConvertCurrencyNumberToWordPipe,
    GenerateName,
    PaymentTypePipe,
    FileUploaderComponent,
    FileDownloaderComponent,
    PdfFilePreviewComponent,
    PdfFilePreviewComponent,
    TimelineComponent,
    ConfirmComponent,
    ChangeStatusPopupComponent,
    RabillExcelUploadComponent,
    ConfirmationPopupComponent,
    CommonFinanceComponent,
    PaymentProcessComponent,
    PaymentProcessCustomerComponent,
    PaymentMethodsComponent,
    OrderPaymentsComponent,
    ContractComponent,
    SecondaryAccountComponent,
    AddSecondaryPaymentModalComponent,
    AddCustomerBankAccComponent,
    MarkPaymentReceivedComponent,
    InvoiceDetailsComponent,
    CreateNoteComponent,
    CreateInvoiceComponent,
    GenericNoteComponent,
    ProformaPaymentScheduleComponent,
    TaxPaymentScheduleComponent,
    AdvancePoSummaryComponent,
    FilterGridComponent,
    FilterPipe,
    PreviewInvoiceComponent,
    PreviewNoteComponent,
    InvoiceSummaryAccordionComponent,
    ChargesAccordionComponent,
    PaymentScheduleAccordionComponent,
    NoteListComponent,
    RejectDocumentComponent,
    PaymentChargesComponent,
    TimelineHorizontalComponent,
    LedgerListComponent,
    GeneralSelectDropdownComponent,
    BillInvoiceDetailsComponent,
    LedgerDetailsComponent,
    PermissionsDirective,
    UnallocatedAmountComponent,
    TimelineTableComponent,
    NoteComponent,
    BillNumberComponent,
    BillCreatePaymentScheduleComponent,
    NoteReasonComponent,
    BillPaymentsComponent,
    BillNotePreviewComponent,
    TimelineV2Component,
    MarkTransactionPaidPopupComponent,
    MarkTransactionMenuComponent,
    AdvanceSplitsComponent,
    NoteSummaryComponent,
    CreateGstSupplierComponent,
    ContractNumberComponent,
    AddressComponent,
    EInvoiceSelectComponent,
    BankDetailsSelectComponent,
    StatusNotificationComponent,
    PaymentOTPinputComponent,
    CurrencyFormatterPipe,
    BillFinanceComponent,
    BillDetailsComponent,
    BillTimelineComponent,
    DocumentFileUploadComponent,
    GeneralRemarkComponent,
    FactoryTaxInvoiceComponent,
    FactoryBillInvoiceDetailsComponent,
    BillTaxInvoiceComponent,
    PaymentRequestUploadViewComponent,
    ProformaInvoiceComponent,
    SelectPaymentRequestComponent,
    TaxAgainstInvoiceComponent,
    PaymentViewComponent,
    PaymentDetailsComponent,
    PaymentDocumentsComponent,
    PerfomaInvoiceAdvanceComponent,
    ProformInvoiceComponent,
    InspectionComponent,
    PdcComponent,
    EWayBillComponent,
    InpectionReportComponent,
    LrComponent,
    PackingListComponent,
    PaymentWighSlipComponent,
    TaxInvoiceComponent,
    PaymentFinanceComponent,
    PendingPaymentComponent,
    PaymentViewRouteComponent,
    AdvanceComponent,
    PaymentDetailFactoryComponent,
    SupplierNoteViewComponent,
    SupplierNoteDetailsTimeline,
    SupplierNoteDetailsComponent,
    SupplierNoteFinanceComponent,
    SupplierPaymentsComponent,
    TcsAmountComponent,
    MoveInvoiceContractComponent,
    GooglePlacesDirective,
    InvoiceUpdateComponent,
    InvoiceApprovalCreationComponent,
    InvoiceApprovalConfirmationComponent,
    AddBoqToInvoiceComponent,
    RaBillDetailsComponent,
    RaBillItemDetailsComponent,
    RaBillDocumentComponent,
    InvoiceMapRabillComponent,
    InputTrimDirective,
    TrimStatusDirective,
    PositiveNumbersDirective,
    AddressDetailsComponent,
    NotifyAddressPopupComponent,
    MarkGrnMrnComponent,
    MultipleFilesViewerComponent,
    NaturalNumbersDirective,
    SupplierLedgerDetailsComponent,
    SupplierBillDetailsComponent,
    SupplierDebitNoteDetailsComponent,
    SupplierInterestNoteDetailsComponent,
    CurrencySymbolDecodePipe,
    SupplierPaymentDetailsComponent,
    SupplierCreditNoteDetailsComponent,
    FinanceNoteSummaryAccordionComponent,
    ItemListBoqDetailComponent,
    PdfPreviewComponent,
    SupplierNotePickItemPopupComponent,
    PrPaymentScheduleComponent,
    DocReviewedBlockComponent,
    PoPaymentTermsViewComponent,
    TimelineStepperComponent,
    TimelineStepperItemDirective,
    MapBillToInvoiceComponent,
    TransactionLcDuedateComponent,
    EditFinancierTransactionDetailsComponent,
    PaymentTicketTabComponent,
    WarningCardComponent,
    ZetTimelineCustomStepperComponent,
    PaymentPlanComponent,
    GrnApprovalConfirmationComponent,
    ImportBillItemsComponent,
    InputErrorComponent,
    NoteReasonChangeComponent,
    ConfirmationPopupV2Component,
    FinancialDimensionCardComponent,
    NewBoqListViewComponent,
    NewInvoiceItemsComponent,
    NewAddBoqToInvoiceComponent,
    AddressDetailsV2Component,
    CreateInvoiceOldItemsComponent,
    DatesEnabledPickerComponent,
    SiteWarehouseCardComponent,
    MarkLCRepaidPopupComponent,
    NewGenericNoteItemsComponent,
    NewGenericNotesBoqItemsComponent,
    GenericNoteOldItemsComponent,
    ActionPermissionDirective,
    ContractSummaryDetailsComponent,
    ContractComparisonViewComponent,
    ParticularComponent,
    ReversePaymentPopupComponent,
    InvoiceSupportingDocsComponent,
    AuditLogsComponent,
    AuditLogDetailsPopupComponent,
    WorkflowAuditLogsComponent,
    DropdownWithSeparatorComponent,
    MarkFailedTransactionSuccessPopupComponent,
    IndirectReceiptComponent,
    GeneralJournalVouchersComponent,
    MarkFailedTransactionSuccessPopupComponent,
    CompanyDisplayNameDirective,
    MixpanelButtonTrackDirective,
    IndirectPaymentComponent,
    BankToBankGeneralJournalVouchersComponent,
    BankTransactionListPopupComponent,
    BankTransactionCardComponent,
    QuestionnaireComponent,
    DocVerificationSummaryComponent,
    DocVerificationSummaryWrapperComponent,
    DocVerificationComponent,
    ApprovalPendingViewComponent,
    QrSummaryComponent,
    EWayBillInvoiceComponent,
    InvoiceDocumentsComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2GoogleChartsModule,
    NgxExtendedPdfViewerModule,
    NgSelectModule,
    PdfViewerModule,
    NumberFormatDirective,
    FormatNumberPipe,
    AmountFormatterPipe,
    DateTimeFormatPipe,
    FormatUnderscorePipe,
    ConvertCurrencyNumberToWordPipe,
    GenerateName,
    PaymentTypePipe,
    FileUploaderComponent,
    FileDownloaderComponent,
    PdfFilePreviewComponent,
    TimelineComponent,
    ConfirmComponent,
    ChangeStatusPopupComponent,
    RabillExcelUploadComponent,
    ConfirmationPopupComponent,
    ModalModule,
    TooltipModule,
    BsDropdownModule,
    BsDatepickerModule,
    TabsModule,
    PaginationModule,
    AccordionModule,
    PopoverModule,
    ImageViewerModule,
    ToastrModule, // ToastrModule added
    CommonFinanceComponent,
    PaymentProcessComponent,
    PaymentProcessCustomerComponent,
    PaymentMethodsComponent,
    OrderPaymentsComponent,
    ContractComponent,
    SecondaryAccountComponent,
    AddSecondaryPaymentModalComponent,
    AddCustomerBankAccComponent,
    MarkPaymentReceivedComponent,
    InvoiceDetailsComponent,
    CreateNoteComponent,
    FilterGridComponent,
    FilterPipe,
    CreateInvoiceComponent,
    PreviewInvoiceComponent,
    PreviewNoteComponent,
    InvoiceSummaryAccordionComponent,
    ChargesAccordionComponent,
    PaymentScheduleAccordionComponent,
    NoteListComponent,
    RejectDocumentComponent,
    PaymentChargesComponent,
    TimelineHorizontalComponent,
    LedgerListComponent,
    GeneralSelectDropdownComponent,
    BillInvoiceDetailsComponent,
    LedgerDetailsComponent,
    NgMultiSelectDropDownModule,
    PermissionsDirective,
    UiSwitchModule,
    UnallocatedAmountComponent,
    TimelineTableComponent,
    NoteComponent,
    BillNumberComponent,
    BillCreatePaymentScheduleComponent,
    NoteReasonComponent,
    BillPaymentsComponent,
    BillNotePreviewComponent,
    TimelineV2Component,
    MarkTransactionPaidPopupComponent,
    MarkTransactionMenuComponent,
    NoteSummaryComponent,
    CreateGstSupplierComponent,
    CurrencyFormatterPipe,
    BillFinanceComponent,
    BillDetailsComponent,
    BillTimelineComponent,
    DocumentFileUploadComponent,
    GeneralRemarkComponent,
    FactoryTaxInvoiceComponent,
    FactoryBillInvoiceDetailsComponent,
    BillTaxInvoiceComponent,
    PaymentRequestUploadViewComponent,
    ProformaInvoiceComponent,
    SelectPaymentRequestComponent,
    TaxAgainstInvoiceComponent,
    PaymentViewComponent,
    PaymentDetailsComponent,
    PaymentDocumentsComponent,
    PerfomaInvoiceAdvanceComponent,
    ProformInvoiceComponent,
    InspectionComponent,
    PdcComponent,
    EWayBillComponent,
    InpectionReportComponent,
    LrComponent,
    PackingListComponent,
    PaymentWighSlipComponent,
    TaxInvoiceComponent,
    PaymentFinanceComponent,
    PendingPaymentComponent,
    PaymentViewRouteComponent,
    AdvanceComponent,
    PaymentDetailFactoryComponent,
    ZetwerkDatatableModule,
    FilterGridModule,
    TableModule,
    FilterModule,
    ZetListModule,
    SupplierNoteViewComponent,
    SupplierNoteDetailsTimeline,
    SupplierNoteDetailsComponent,
    SupplierNoteFinanceComponent,
    SupplierPaymentsComponent,
    GooglePlacesDirective,
    InvoiceMapRabillComponent,
    ZetTableModule,
    ScrollingModule,
    CarouselModule,
    InputTrimDirective,
    TrimStatusDirective,
    PositiveNumbersDirective,
    NaturalNumbersDirective,
    SupplierLedgerDetailsComponent,
    SupplierBillDetailsComponent,
    SupplierDebitNoteDetailsComponent,
    SupplierInterestNoteDetailsComponent,
    CurrencySymbolDecodePipe,
    SupplierPaymentDetailsComponent,
    SupplierCreditNoteDetailsComponent,
    FinanceNoteSummaryAccordionComponent,
    ItemListBoqDetailComponent,
    PdfPreviewComponent,
    PrPaymentScheduleComponent,
    DocReviewedBlockComponent,
    PoPaymentTermsViewComponent,
    TimelineStepperComponent,
    TimelineStepperItemDirective,
    TransactionLcDuedateComponent,
    StepperModule,
    TimelineModule,
    PaymentTicketTabComponent,
    WarningCardComponent,
    ZetTimelineCustomStepperComponent,
    WidgetModule,
    InputErrorComponent,
    NoteReasonChangeComponent,
    FinancialDimensionModule,
    AddressModule,
    ItemMasterModule,
    FinancialDimensionCardComponent,
    WidgetModule,
    CustomPluralPipe,
    ConfirmationPopupV2Component,
    ActionPermissionDirective,
    ContractSummaryDetailsComponent,
    ContractComparisonViewComponent,
    ParticularComponent,
    DatesEnabledPickerComponent,
    SiteWarehouseCardComponent,
    ConfirmationPopupV2Component,
    MarkLCRepaidPopupComponent,
    BankMasterModule,
    AuditLogsComponent,
    ZetAppSharedModule,
    WorkflowAuditLogsComponent,
    DropdownWithSeparatorComponent,
    IndirectReceiptComponent,
    CompanyDisplayNameDirective,
    MixpanelButtonTrackDirective,
    IndirectPaymentComponent,
    QuestionnaireComponent,
    DocVerificationSummaryComponent,
    DocVerificationComponent,
    ApprovalPendingViewComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    PermissionsDirective,
    {provide: TIMELINE_CONFIG, useValue: timelineConfig },
    // TabDirective,
    // TabsetComponent,

  ],
  entryComponents: [
    ConfirmationPopupComponent, PdfFilePreviewComponent, SecondaryAccountComponent, FinancialDimensionCardComponent,SiteWarehouseCardComponent,
    AddSecondaryPaymentModalComponent, AddCustomerBankAccComponent, ProformaPaymentScheduleComponent,
    TaxPaymentScheduleComponent, RejectDocumentComponent, StatusNotificationComponent, PaymentOTPinputComponent,
    BillNumberComponent, MarkTransactionPaidPopupComponent, AdvanceSplitsComponent,
    NoteSummaryComponent, ContractNumberComponent, AddressComponent, EInvoiceSelectComponent,
    TcsAmountComponent, MoveInvoiceContractComponent, AddBoqToInvoiceComponent, InvoiceUpdateComponent,InvoiceApprovalCreationComponent,InvoiceApprovalConfirmationComponent, NotifyAddressPopupComponent,
    MarkGrnMrnComponent, MultipleFilesViewerComponent, ItemListBoqDetailComponent, SupplierNotePickItemPopupComponent, TransactionLcDuedateComponent, NoteReasonChangeComponent, MarkLCRepaidPopupComponent,
    EWayBillInvoiceComponent
  ]
})
export class CommonSharedModule {
}
