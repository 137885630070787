export const INVOICE_TYPE_LABEL = {
  PROFORMA_INVOICE: {
    TYPE: 'PROFORMA_INVOICE',
    NAME: 'Proforma Invoice',
    BREADCRUMBNAME: 'Proforma Invoice',
    INVOICE_NO: 'Proforma Invoice No.',
    INVOICE_DATE: 'Proforma Invoice Date',
    BREADCRUMB_PATH: '/finance/proformaInvoice/view/CREATED'
  },
  TAX_INVOICE: {
    TYPE: 'TAX_INVOICE',
    NAME: 'Invoice',
    BREADCRUMBNAME: 'Invoice',
    INVOICE_NO: 'Invoice No.',
    INVOICE_DATE: 'Invoice Date',
    BREADCRUMB_PATH: '/finance/taxInvoice/view/CREATED'
  }
};


export const ALL_NOTE_TYPES = {
  CREDIT_NOTE: 'CREDIT_NOTE',
  DEBIT_NOTE: 'DEBIT_NOTE'
};

export const IRN_STATUS_MAP = {
  CREATED: 'Created',
  GENERATED: 'IRN Generated',
  CANCELLED: 'Cancelled'
};

export const INVOICE_STATUS = {
  CREATED: 'Created',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  DISCARDED: 'Discarded'
}


export enum INVOICE_STATUS_ENUM {
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
};

export const DEFAULT_SUPPORTING_DOC_MAPPER = {
  'ZETWERK_INVOICE': 'Zetwerk Invoice',
  'ZETWERK_E_WAY_BILL': 'Zetwerk E-Way Bill',
  'PACKING_LIST':'Packing List',
  'GRN/MRN' : 'GRN/MRN',
  'LR_NO/DOCKET_NO':'LR No / Docket No',
  'MTC': 'MTC',
  'RAILWAY_RECEIPT': 'Railway Receipt'
};

export const DEFAULT_SUPPORTING_DOCS = {
  '1: GRN/MRN': 'GRN/MRN',
  '2: LR No / Docket No': 'LR No / Docket No',
  '3: Packing List': 'Packing List',
  '5: Zetwerk Invoice' : 'Zetwerk Invoice',
  '4: Zetwerk E-Way Bill': 'Zetwerk E-Way Bill'
};

export enum SUPPORTING_DOCS_STATUS_ENUM {
    VERIFICATION_PENDING = 'VERIFICATION_PENDING',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
}

export enum INVOICE_TYPE_ENUM {
  TAX_INVOICE = 'TAX_INVOICE',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
}
